<template>
  <div>
    <div class="section-title section-title_app">
      <h3>REPORTE DE ORDENES POR SUCURSAL</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <div class="column is-2">
            <b-field label="Fecha inicio"
                      label-position="on-border">
              <b-datepicker placeholder="2023"
                              v-model="date_order_start" @imput="() => this.getData()"
                              :max-date="maxDatetime"
                              nearby-selectable-month-days
                              :min-date="minDatetime">

              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Fecha fin"
                      label-position="on-border">
              <b-datepicker placeholder="2023"
                          v-model="date_order_end" @input="() => this.getData()"
                          :max-date="maxDatetime"
                          nearby-selectable-month-days
                          :min-date="minDatetime">

              </b-datepicker>
            </b-field>
          </div>

          <div class="column is-1">
            <b-tooltip label="Exportar excel">
              <b-button size="is-small" type="is-primary" @click="handleExport">Exportar excel</b-button>
            </b-tooltip>
          </div>
          <div class="column is-1">
            <b-tooltip label="Exportar pdf">
              <b-button size="is-small" type="is-primary" @click="showModal=true">Exportar pdf</b-button>
            </b-tooltip>
          </div>
          <div class="column is-6">
            <span><b>Total de ordenes encontradas: {{ total }}</b></span>
          </div>
        </div>
      </div>

      <div class="column is-12 has-background-white">
          <section>
            <b-table
                  class="is-size-7"
                  :data="data"
                  :loading="loading"
                  @page-change="getData"
                  paginated
                  :per-page="perPage"
                  :current-page="currentPage"
                  backend-pagination
                  :total="total"
                  bordered
                  narrowed
                  sticky-header
                  height="100%">
              <b-table-column label="Orden" field="order" v-slot="props">
                {{ props.row.order }}
              </b-table-column>
              <b-table-column label="Fecha estudio" field="date" v-slot="props">
                {{ props.row.date }}
              </b-table-column>
              <b-table-column label="Entrega" field="delivery_date" v-slot="props">
                {{ props.row.delivery_date }}
              </b-table-column>
              <b-table-column label="Procedencia" field="branchOffice" v-slot="props">
                {{ props.row.branchOffice }}
              </b-table-column>
              <b-table-column label="SubProcede" field="sub_branch_office" v-slot="props">
                {{ props.row.sub_branch_office }}
              </b-table-column>
              <b-table-column label="No Local Pase/Ref" field="no_local" v-slot="props">
                {{ props.row.no_local }}
              </b-table-column>
              <b-table-column label="Estatus" field="status" v-slot="props">
                {{ props.row.status }}
              </b-table-column>
              <b-table-column label="Toma" field="foreign_work" v-slot="props">
                {{ props.row.foreign_work }}
              </b-table-column>
              <b-table-column label="Empresa" field="company_key" v-slot="props">
                {{ props.row.company_key }}
              </b-table-column>
              <b-table-column label="Paciente" field="patient" v-slot="props">
                {{ props.row.patient_code + ' ' + props.row.full_name }}
              </b-table-column>
              <b-table-column label="Médico" field="medical" v-slot="props">
                {{ props.row.medical_key + ' ' + props.row.medical_name }}
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                          icon="box-open"
                          size="is-large">
                      </b-icon>
                    </p>
                    <p>No hay resultados para mostrar.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </section>
      </div>
    </div>
    <b-modal v-model="showModal">
      <iframe class="lapi__iframe" :src="reportUrl"></iframe>
    </b-modal>
  </div>



</template>

<script>
export default {
  name: "OrdersReport",
  data() {
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 1, 0, 0)
    const minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 3, 12, 1)
    return {
      date: new Date(),
      date_order_start: new Date(),
      date_order_end: new Date(),
      maxDatetime: maxYear,
      minDatetime: minYear,
      order: '',
      data: [],
      loading: true,
      branch_office: '',
      total: 0,
      showModal: false,
      perPage: 50,
      currentPage: 1,
    }
  },
  computed: {
    branchOffices() {
      return this.$store.state.main.accountBranchOffices;
    },
    transformDate(){
      const new_date= `${this.date_order_start.getFullYear()}-${(this.date_order_start.getMonth()+1).toString().padStart(2, '0')}-${this.date_order_start.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
    transformDate2(){
      const new_date= `${this.date_order_end.getFullYear()}-${(this.date_order_end.getMonth()+1).toString().padStart(2, '0')}-${this.date_order_end.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
    reportUrl() {
      const url = new URL(`${process.env.VUE_APP_API_URL}/reports/orders-report?date_order_start=${this.transformDate}&date_order_end=${this.transformDate2}&total=${this.total}`);
      return url.href;
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    this.getData()
  },
  methods: {
    getData( page = 1){
        this.loading = true;
        console.log('getData');
        const params = {
          perPage: this.perPage, page,
          date_order_start: this.transformDate,
          date_order_end: this.transformDate2,
        }
        this.$http.get('/reports/order',{params}).then(async ({data}) => {
          console.log(data);
          console.log(data.meta.total);
          this.data =data.data;
          this.total = data.meta.total;
          this.currentPage= page;
        }).finally(() => {
          this.loading = false;
        })
    },
    handleExport(){
      this.$loader.show();
      const params = {
        date_order_start: this.transformDate,
        date_order_end: this.transformDate2,
      }
      this.$http.get('/reports/order/export',{params, responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`report order .xlsx`)
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        this.$loader.hide();
      })
    },
  },
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>